<template>
  <a-form :form="form" @submit.prevent="validateCampus" class="mt-3">
   <a-form-item label="School" :label-col="{ sm: 4 }" :wrapper-col="{ xs: 24, sm: 20, md: 10 }">
      <a-select
        showSearch
        placeholder="Select a School"
        optionFilterProp="children"
        v-decorator="[
          'SchoolID',
          { rules: [{ required: true, message: 'Please select a school!' }] },
        ]"
        :filterOption="filterOption">
          <a-select-option 
            v-for="school in schoolList"
            :key="school.id" 
            :value="school.id">
              {{school.Name}}
          </a-select-option>
      </a-select>
   </a-form-item>
    <a-form-item label="Name" :label-col="{ sm: 4 }" :wrapper-col="{ xs: 24, sm: 20, md: 10 }">
      <a-input placeholder="Name" v-decorator="['Name', { rules: [{ required: true, message: 'Please input a campus name!'}] }]"></a-input>
    </a-form-item>
    <a-form-item label="Description" :label-col="{ sm: 5, md: 4 }" :wrapper-col="{ xs: 24, sm: 19, md: 10 }">
      <a-input placeholder="Description" v-decorator="['Description', { rules: [{ required: true, message: 'Please input a campus description!'}] }]"></a-input>
    </a-form-item>
    <a-form-item label="Status" :label-col="{ sm: 4 }" :wrapper-col="{ xs: 24, sm: 20, md: 10}">
      <a-select
        placeholder="Select a Status"
        v-decorator="[
            'Status',
            { initialValue: 1 },
            { rules: [{ required: true, message: 'Please select a status!' }] },
          ]">
        <a-select-option :value="1">
            Active
        </a-select-option>
        <a-select-option :value="0">
            Deleted
        </a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item :wrapper-col="{ xs: 24, md: {span: 3, offset: 6 }}">
      <a-button htmlType="submit" type="primary" shape="round" :block="true">Add</a-button>
    </a-form-item>
  </a-form>
</template>
<script>
import campusService from '@/services/campus';

export default {
  props: ['schoolList'],
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'campus' });
  },
  methods: {
    validateCampus() {
      this.form.validateFields( (err, values) => {
        if (!err) {
          this.createCampus(values);
        }
      });
    },
    async createCampus(values) {
      const result = await campusService.createCampus(values);
      if(result) {
        this.$message.success('Campus Created!');
        this.form.resetFields();
      }
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
  }
}
</script>
<style scoped>

</style>